<template>
  <span>
    <Alert />
    <b-card class="h-100" no-body>
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("views.ou.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox
              v-model="Enable"
              id="OrganizationalUnitsShowEnabled"
              name="check-button"
              switch
              >{{ $t("views.ou.showEnabled") }}</b-form-checkbox
            >
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <AddOrganizationUnit
                  v-if="userRoles.isAdminCustomer && Enable"
                />
                <EditOrganizationUnit
                  v-if="userRoles.isAdminCustomer && Enable"
                  :selected="selected"
                />
                <b-button
                  v-if="userRoles.isAdminCustomer"
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.ou.deactivateOU')"
                  :disabled="isSelected"
                  @click="remove"
                >
                  <b-icon-trash />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.ou.Parameters')"
                  :disabled="isSelected"
                  @click="toParameters"
                >
                  <b-icon-sliders />
                </b-button>
                <b-button
                  v-if="userRoles.isAdminCustomer"
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.ou.Activate')"
                  :disabled="isSelected"
                  @click="activate"
                >
                  <b-icon-check />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="overflow-auto p-0">
        <b-table
          ref="OrganizationUnits"
          :busy="busy"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          :keyword="keyword"
          selectable
          small
          outlined
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <simple-loader />
            </div>
          </template>
          <template v-slot:cell(nameOrganizationalUnit)="data">
            <div>
              {{ data.value }}
              <font-awesome-icon
                id="disabled-wrapper"
                icon="crown"
                class="text-warning"
                v-if="data.item.default"
              />
              <b-tooltip target="disabled-wrapper">{{
                $t("views.ou.deafult")
              }}</b-tooltip>
            </div>
          </template>
          <template v-slot:cell(enabled)="data">
            <div v-if="data.value" class="text-success">
              <strong>
                <b-icon-check />
              </strong>
            </div>
            <div v-else class="text-danger">
              <strong>
                <b-icon-x />
              </strong>
            </div>
          </template>
        </b-table>
      </b-card-body>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="center"
      ></b-pagination>
    </b-card>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddOrganizationUnit from "@/components/OrganizationalUnit/AddOrganizationalUnit";
import EditOrganizationUnit from "@/components/OrganizationalUnit/EditOrganizationalUnit";
import Alert from "@/components/general/alert";
import router from "../router";

export default {
  components: {
    AddOrganizationUnit,
    EditOrganizationUnit,
    Alert,
  },
  watch: {
    Enable() {
      const that = this;
      this.busy = true;
      this.getOUs({
        api: this.$api,
        enabled: this.Enable,
        idOURol: this.$session.get("UnitOrganitaions"),
      })
        .then(() => {
          that.busy = false;
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
    UnitOrganitaion() {
      const that = this;
      this.busy = true;
      this.getOUs({
        api: this.$api,
        enabled: this.Enable,
        idOURol: this.$session.get("UnitOrganitaions"),
      })
        .then(() => {
          that.busy = false;
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
  },
  data() {
    return {
      Enable: true,
      perPage: 20,
      currentPage: 1,
      busy: false,
      selected: [],
      keyword: "",
    };
  },

  methods: {
    ...mapActions("OrganizationalUnit", [
      "getOUs",
      "UpdateOU",
      "ActiveOU",
      "DeleteOU",
    ]),
    onRowSelected(items) {
      this.selected = items;
    },
    activate() {
      this.ActiveOU({
        idOrganizationalUnit: this.selected[0].idOrganizationalUnit,
      })
        .then((response) => {
          this.$bvToast.toast(response.text, {
            variant: "success",
            title: this.$t("views.ou.Activated"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.busy = false;
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.ou.Activated"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.busy = false;
        });
    },
    remove() {
      this.DeleteOU({
        idOrganizationalUnit: this.selected[0].idOrganizationalUnit,
      })
        .then((response) => {
          this.$bvToast.toast(response.text, {
            variant: "success",
            title: this.$t("views.ou.Removed"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.busy = false;
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.ou.Removed"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.busy = false;
        });
    },
    toParameters() {
      router.push({
        name: "Parameters",
        params: {
          idOrganizationalUnit: this.selected[0].idOrganizationalUnit,
        },
      });
    },
  },
  mounted() {
    const that = this;
    this.busy = true;
    this.getOUs({
      api: this.$api,
      enabled: this.Enable,
      idOURol: this.UnitOrganitaion,
    })
      .then(() => {
        that.busy = false;
      })
      .catch((e) => {
        console.log(e);
        that.busy = false;
      });
  },
  computed: {
    ...mapGetters("OrganizationalUnit", ["UnitOrganitaion"]),
    ...mapGetters("OrganizationalUnit", ["OUs"]),
    ...mapGetters({ userRoles: "GET_USERROLES" }),
    fields() {
      return [
        {
          key: "idOrganizationalUnit",
          sortable: true,
          label: this.$t("views.ou.fields.Id"),
        },
        {
          key: "nameOrganizationalUnit",
          sortable: true,
          label: this.$t("views.ou.fields.Name"),
        },
        {
          key: "enabled",
          label: this.$t("views.ou.fields.Enabled"),
        },
      ];
    },
    rows() {
      return this.items.length;
    },
    isSelected() {
      return this.selected.length < 1;
    },
    items() {
      return this.keyword
        ? this.OUs.filter((item) =>
            item.nameOrganizationalUnit.includes(this.keyword)
          )
        : this.OUs;
    },
  },
};
</script>

<style lang="scss" scoped></style>
