<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.ou.Add')"
    id="create-help-article-button"
  >
    <b-icon-plus />
    <b-modal
      :id="idModal"
      ref="create-help-article-modal"
      :title="$t('views.ou.Add')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      :cancel-title="$t('general.cancel')"
      :ok-title="$t('general.save')"
      :busy="loading"
      @ok="handleOk"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="$t('views.ou.modalOU.nameLabel')"
          label-for="name-input"
          :invalid-feedback="$t('views.ou.modalOU.nameInvalidFeedback')"
          label-cols="12"
          content-cols="12"
          :state="state.name"
        >
          <b-form-input
            id="name-input"
            :disabled="loading"
            :placeholder="$t('views.ou.modalOU.namePlaceholder')"
            v-model="form.name"
            :state="state.name"
            maxlength="40"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        name: null,
      },
      state: {
        name: null,
      },
      loading: false,
      idModal: "create-ou-modal",
    };
  },
  methods: {
    ...mapActions("OrganizationalUnit", ["AddOU"]),
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.AddOU({
        nameOrganizationalUnit: this.form.name,
      })
        .then((response) => {
          this.$bvToast.toast(response.information.text, {
            variant: "success",
            title: this.$t("views.ou.Add"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide(this.idModal);
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.ou.Add"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.state = {
            name: null,
          };
        });
    },
    resetModal() {
      this.form = {
        name: null,
      };
      this.state = {
        name: null,
      };
      this.loading = false;
    },
    checkFormValidity() {
      let valid = true;
      if (!this.form.name) {
        this.state.name = false;
        valid = false;
      } else {
        this.state.name = true;
      }
      return valid;
    },
  },
};
</script>
